import React from "react"
import { Link } from "gatsby"
import ServiceListWrapper from "./ServiceListWrapper"
import GetInTouch from "../get-in-touch/get-in-touch"

const ServiceList = () => {
  return (
    <div className="content">
      <div className="single-page-decor"></div>
      <div className="single-page-fixed-row">
        <div className="scroll-down-wrap">
          <div className="mousey">
            <div className="scroller"></div>
          </div>
          <span>Runter scrollen</span>
        </div>
        <Link to="/" className="single-page-fixed-row-link">
          <i className="fal fa-arrow-left"></i> <span>Zum Start</span>
        </Link>
      </div>

      <ServiceListWrapper />
      <GetInTouch />
    </div>
  )
}

export default ServiceList
