import React, { Component } from "react"
import { Container, Row } from "reactstrap"
import { Link } from "gatsby"
import "./get-in-touch.css"
class GetInTouch extends Component {
  render() {
    return (
      <section id="overimage" className="dark-bg2 small-padding order-wrap">
        <Container>
          <Row>
            <div className="col-md-8">
              <h3>Bereit, Ihr Projekt zu bestellen?</h3>
            </div>
            <div className="col-md-4">
              <Link to="/kontakt" className="btn flat-btn color-btn">
                Kontaktieren Sie mich
              </Link>
            </div>
          </Row>
        </Container>
      </section>
    )
  }
}

export default GetInTouch
