import React, { useState } from "react"
import ServiceListItem from "./ServiceListItem"
import { useStaticQuery, graphql } from "gatsby"
const serviceQuery = graphql`
  {
    services: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/services/" } }
      sort: { fields: frontmatter___position, order: ASC }
    ) {
      nodes {
        html
        excerpt
        frontmatter {
          id
          title
          image {
            id
            childImageSharp {
              fixed(width: 400, height: 900, trim: 1.5, fit: COVER) {
                src
              }
            }
          }
          pricing
          categories
          icon
        }
      }
    }
  }
`

const ServiceListWrapper = () => {
  const { services } = useStaticQuery(serviceQuery)
  const [currentImage, setCurrentImage] = useState(false)
  const handleCurrentImage = index => {
    setCurrentImage(
      services.nodes[index].frontmatter.image.childImageSharp.fixed.src
    )
  }
  return (
    <section className="dark-bg no-padding">
      <div className="hidden-info-wrap-bg">
        <div
          className="bg active"
          style={{ backgroundImage: `url(${currentImage})` }}
        ></div>

        <div className="bg-ser"></div>
        <div className="overlay"></div>
      </div>

      <div className="hidden-info-wrap">
        <div className="hidden-info fl-wrap">
          <div className="hidden-info-title">Meine Dienstleistungen</div>
          <div className="hidden-works-list fl-wrap">
            {services.nodes.map((node, idx) => {
              return (
                <ServiceListItem
                  key={idx}
                  index={idx}
                  handleCurrentImage={handleCurrentImage}
                  {...node}
                />
              )
            })}
          </div>
        </div>
      </div>

      <div className="fl-wrap limit-box"></div>
    </section>
  )
}

export default ServiceListWrapper
