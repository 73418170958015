import React, { useState } from "react"
import ServiceBgLongImage from "../../images/bg/long/service.jpg"

const ServiceListItem = ({
  frontmatter,
  html,
  excerpt,
  index,
  handleCurrentImage,
}) => {
  const [isHover, setHover] = useState(false)
  return (
    <div
      id={frontmatter.id}
      className="hidden-works-item serv-works-item fl-wrap"
      data-bgscr={ServiceBgLongImage}
      onMouseOver={() => {
        setHover(true)
        handleCurrentImage(index)
      }}
      onMouseOut={() => {
        setHover(false)
      }}
    >
      <div className="hidden-works-item-text">
        <h3>{frontmatter.title}</h3>
        <ul className="fl-wrap">
          {frontmatter.categories &&
            frontmatter.categories.map((cat, idx) => {
              return (
                <li className="works-category" key={idx}>
                  {cat}
                </li>
              )
            })}
        </ul>
        <div
          className="blog-content"
          dangerouslySetInnerHTML={{ __html: html }}
        ></div>

        <div className="clearfix"></div>
        <div className="serv-price">{frontmatter.pricing}</div>
        <span className="serv-number">0{index + 1}.</span>
        <div className="serv-icon" style={{ opacity: isHover ? 1 : 0 }}>
          <i className={`fal ${frontmatter.icon}`}></i>
        </div>
      </div>
    </div>
  )
}

export default ServiceListItem
